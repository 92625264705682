import BreakLine from "components/ui/BreakLine";
import React from "react";

function SummarySection({ label = "", icon, children }) {
	return (
		<div className="bg-white rounded-md flex flex-col gap-4 p-4">
			<div className="flex justify-between font-semibold md:text-lg w-full items-center">
				{label}
				{!!icon && <span className="w-5 h-5 text-jane-500">{icon}</span>}
			</div>
			<BreakLine />
			<div className="grid grid-cols-2 gap-4">{children}</div>
		</div>
	);
}

export default SummarySection;
