import Signature from "components/checkout/Signature";
import { ModalDialog } from "components/modals/ModalDialog";
import React, { forwardRef } from "react";

const SignatureModal = forwardRef(({ signatureMethods, onCancel }, ref) => {
	const {
		signatureRef,
		signatureModalOpen,
		setSignatureModalOpen,
		signatureUrl,
		submitSignature,
		clear,
		loading,
		handleEnd,
		isSignatureOverriten,
		previewUrl,
	} = signatureMethods;

	return (
		<ModalDialog
			isOpen={signatureModalOpen}
			disabled={!signatureUrl && !isSignatureOverriten}
			onSubmit={submitSignature}
			onClose={() => {
				setSignatureModalOpen(false);
				onCancel();
			}}
			maxWidth={400}
			isLoading={loading}
			title="חתימה דיגיטלית"
			description="השאירו חתימה דיגיטלית על מנת לאשר ייפוי של בית המרקחת"
		>
			{signatureModalOpen && (
				<Signature
					ref={signatureRef || ref} // Forwarding the ref
					clearOnResize={true}
					clear={clear}
					handleEnd={handleEnd}
					signatureUrl={signatureUrl}
					previewUrl={previewUrl}
				/>
			)}
		</ModalDialog>
	);
});
SignatureModal.displayName = "SignatureModal";
export default SignatureModal;
