import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const checkoutCartAtom = atomWithStorage("userCheckoutCart", null);

export const useCheckoutCartAtom = () => {
	const [checkoutCart, setCheckoutCart] = useAtom(checkoutCartAtom);

	const removeCheckoutCart = () => {
		setCheckoutCart(null);
	};

	return { checkoutCart, setCheckoutCart, removeCheckoutCart };
};
