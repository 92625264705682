import { Disclosure, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import useWindowDimensions from "components/hooks/useWindowDimensions";
import React, { useEffect, useRef, useState } from "react";
import { classNames } from "util/common";
import { CHECKOUT_STEPS } from "util/types";
import { WBO } from "util/types";

function StepDisclosure({
	title,
	icon,
	isOpen = false,
	children,
	disabled = false,
	number = 0,
	completed = false,
	step,
	setStep,
	nextStep,
	currentStep,
	validateForm,
}) {
	const buttonRef = useRef(null);
	const disclosureRef = useRef(null);
	const { width } = useWindowDimensions();
	const { USER_INFO, LICENSE, PRESCRIPTION } = CHECKOUT_STEPS;

	const disclosureHeight = buttonRef.current?.offsetHeight;
	const gapBetweenDisclosures = width > WBO["lg"] ? 16 : 8;
	const distanceToTopEdge = 144;

	const topPosition = {
		[USER_INFO]: distanceToTopEdge,
		[LICENSE]: disclosureHeight + gapBetweenDisclosures * 2 + distanceToTopEdge,
		[PRESCRIPTION]:
			disclosureHeight * 2 + gapBetweenDisclosures * 3 + distanceToTopEdge,
	};

	useEffect(() => {
		if (buttonRef.current) {
			const isCurrentlyOpen =
				buttonRef.current.getAttribute("data-open") === "true";
			if (isOpen && !isCurrentlyOpen) {
				buttonRef.current.setAttribute("data-initiated-by", "code");
				buttonRef.current.click();
				window.scrollTo({
					top: topPosition[step],
					behavior: "smooth",
				});
			}
			if (!isOpen && isCurrentlyOpen) {
				buttonRef.current.setAttribute("data-initiated-by", "code");
				buttonRef.current.click();
			}
		}
	}, [isOpen]);

	const handleButtonClick = async e => {
		const initiatedBy = buttonRef.current.getAttribute("data-initiated-by");

		if (initiatedBy === "code") {
			buttonRef.current.setAttribute("data-initiated-by", "click");
			return;
		}

		if (initiatedBy === "click") {
			e.preventDefault();
			if (validateForm !== undefined) {
				const isValid = await validateForm(currentStep);
				if (isValid && !isOpen) {
					setStep(step);
				} else {
					e.preventDefault();
					return;
				}
			} else {
				if (!isOpen && !disabled) {
					setStep(step);
				}
			}
		}
	};
	return (
		<Disclosure
			as="div"
			ref={disclosureRef}
			className="w-full"
			defaultOpen={isOpen}
		>
			{({ open }) => (
				<>
					<Disclosure.Button
						ref={buttonRef}
						data-initiated-by="click"
						onClick={handleButtonClick}
						data-open={open}
						className={classNames(
							"w-full bg-white flex justify-between p-4 md:p-6",
							open ? "rounded-t-md" : "rounded-md",
						)}
					>
						<span className="flex gap-2 items-center">
							{completed ? (
								<span className="w-5 h-5 flex items-center justify-center bg-emerald-500 rounded-full">
									<CheckIcon className="text-white w-4 h-4" />
								</span>
							) : (
								<span className="bg-jane-200 rounded-full w-5 h-5 flex items-center justify-center font-bold text-xs">
									{number}
								</span>
							)}
							<span className={open ? "font-semibold" : ""}>{title}</span>
						</span>
						<span className="text-jane-500 w-6 h-4">{icon}</span>
					</Disclosure.Button>
					<Transition
						enter="duration-200 ease-out"
						enterFrom="opacity-0 h-0"
						enterTo="opacity-100 h-fit"
						leave="duration-300 ease-out"
						leaveFrom="opacity-100 h-fit"
						leaveTo="opacity-0 h-0"
					>
						{open && (
							<div className="h-[1px] w-full px-4 bg-white">
								<div className="bg-jane-200 rounded-full h-[1px]" />
							</div>
						)}
						<Disclosure.Panel className="origin-top transition bg-white p-4 rounded-b-md border-jane-200">
							{children}
						</Disclosure.Panel>
					</Transition>
				</>
			)}
		</Disclosure>
	);
}

export default StepDisclosure;
