import FileField from "components/fields/FileField";
import IdField from "components/fields/IdField";
import NameField from "components/fields/NameField";
import { Button } from "components/form/Button";
import { FILE_NAMES } from "components/hooks/useDownload";
import React from "react";
import { userDocTypes } from "util/types";

function IdCheckout(checkoutMethods) {
	const { userInfo, setUserInfo, nextStep, updatedFields, claims } =
		checkoutMethods;

	const {
		register,
		setValue,
		control,
		watch,
		getValues,
		handleSubmit,
		formState: { errors, isValid, isDirty, isSubmitting },
	} = checkoutMethods.idForm;

	const onSubmit = params => {
		setUserInfo(params);
		nextStep(isValid);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="flex flex-col gap-4 md:flex-row">
				<FileField
					storageName="documents"
					documentType={userDocTypes.user_id}
					previewUrl={watch("previewUrl")}
					name={FILE_NAMES.ID}
					control={control}
					errors={errors}
					className="w-full h-full"
					setValue={setValue}
				/>
				<span className="w-full flex flex-col gap-4">
					<IdField register={register} disabled={!!claims.id_number} />
					<NameField register={register} errors={errors} />
					<NameField
						register={register}
						label="שם משפחה"
						placeholder="שם משפחה"
						registerName="last_name"
						name="lastName"
						errors={errors}
					/>
				</span>
			</div>
			<div className="flex flex-col md:flex-row justify-between gap-4 mt-4">
				<Button onClick={nextStep} className="hidden md:flex" primary>
					המשך לרישיון
				</Button>
			</div>
		</form>
	);
}

export default IdCheckout;
