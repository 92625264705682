import _ from "lodash";

const MEDICAL_CONDITIONS_CHOICES = [
	{
		label: " בחירת התוויה",
		value: null,
	},
	{
		label: "כאב - Pain",
		value: "pain",
	},
	{
		label: "פוסט טראומה - PTSD",
		value: "ptsd",
	},
	{
		label: "סרטן - Cancer",
		value: "cancer",
	},
	{
		label: "קרוהן - Crohn",
		value: "crohn",
	},
	{
		label: "קוליטיס - Colitis",
		value: "colitis",
	},
	{
		label: "איידס - AIDS",
		value: "aids",
	},
	{
		label: "טרשת נפוצה - Mutiple Sclerosis",
		value: "ms",
	},
	{
		label: "פרקינסון - Parkinson",
		value: "parkinson",
	},
	{
		label: "טורט - Tourette",
		value: "tourette",
	},
	{
		label: "אפילפסיה - Epilepsy",
		value: "epilepsy",
	},
	{
		label: "טיפול פליאטיבי - Palliative Care",
		value: "palliative_care",
	},
	{
		label: "אוטיזם - Autism",
		value: "autism",
	},
	{
		label: "דמנציה - Dementia",
		value: "dementia",
	},
	{
		label: "אחר - Other",
		value: "other",
	},
];

const ALLOWED_MEDICAL_CONDITIONS = MEDICAL_CONDITIONS_CHOICES.filter(
	c => c.value !== "pain" && c.value !== "ptsd" && c.value !== "other",
);

const NOT_ALLOWED_MEDICAL_CONDITIONS = MEDICAL_CONDITIONS_CHOICES.filter(
	c => c.value === "pain" || c.value === "ptsd" || c.value === "other",
);

const sortedAllowedMedicalConditions = _.sortBy(ALLOWED_MEDICAL_CONDITIONS, [
	"label",
	"value",
]);

const sortedNotAllowedMedicalConditions = _.sortBy(
	NOT_ALLOWED_MEDICAL_CONDITIONS,
	["label", "value"],
);

const sortedMedicalConditions = [
	[..._.sortBy(MEDICAL_CONDITIONS_CHOICES, ["label", "value"])].filter(
		c => c.value !== "other",
	),
	NOT_ALLOWED_MEDICAL_CONDITIONS.filter(c => c.value === "other").flat(),
].flat();

export {
	MEDICAL_CONDITIONS_CHOICES,
	ALLOWED_MEDICAL_CONDITIONS,
	NOT_ALLOWED_MEDICAL_CONDITIONS,
	sortedAllowedMedicalConditions,
	sortedNotAllowedMedicalConditions,
	sortedMedicalConditions,
};
