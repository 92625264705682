import React from "react";
import SummarySection from "./SummarySection";
import { LocationMarkerIcon, TruckIcon } from "@heroicons/react/outline";
import InfoCell from "components/ui/InfoCell";
import { DELIVERY_METHODS } from "util/types";
import CartPreview from "./CartPreview";
import {
	FaClipboardList,
	FaEdit,
	FaIdBadge,
	FaNotesMedical,
	FaSpinner,
} from "react-icons/fa";
import { Button } from "components/form/Button";
import SignatureModal from "components/forms/SignatureModal";
import { formatDate } from "util/common";
import OrderSuccess from "./OrderSuccess";

function SummaryStep(checkoutMethods) {
	const {
		idForm,
		licenseForm,
		summaryForm,
		summaryForm: { register, formState },
		getRelevantPrescription,
		cart,
		store,
		submitOrder,
		signatureMethods,
		orderInfo,
		deliveryMethod,
		getDeliveryData,
		loading,
		isWidget,
	} = checkoutMethods;
	const relevantPrescription = getRelevantPrescription();
	const deliveryData = getDeliveryData();
	if (orderInfo.submitted) {
		return <OrderSuccess orderInfo={orderInfo} />;
	}

	return (
		<>
			<div
				className={`flex flex-col gap-4 ${
					!isWidget && "md:grid md:grid-cols-2"
				}`}
			>
				<div className="flex flex-col gap-4">
					<div
						className={`bg-white rounded-md overflow-hidden flex flex-col ${
							!isWidget && "md:hidden"
						}`}
					>
						<CartPreview
							storeId={cart?.storeId}
							deliveryPrice={
								deliveryMethod === DELIVERY_METHODS.DELIVERY
									? deliveryData.delivery_cost
									: undefined
							}
						/>
					</div>

					<SummarySection label="מטופל" icon={<FaIdBadge />}>
						<InfoCell label="שם" value={idForm.getValues("first_name")} />
						<InfoCell label="שם משפחה" value={idForm.getValues("last_name")} />
						<InfoCell label="ת.ז" value={idForm.getValues("id_number")} />
					</SummarySection>
					{licenseForm.getValues("authorized_person_active") && (
						<SummarySection label="מיופה כוח " icon={<FaNotesMedical />}>
							<InfoCell
								label="שם"
								value={licenseForm.getValues("authorized_person_first_name")}
							/>
							<InfoCell
								label="שם משפחה"
								value={licenseForm.getValues("authorized_person_last_name")}
							/>
							<InfoCell
								label="ת.ז"
								value={licenseForm.getValues("authorized_person_id_number")}
							/>
						</SummarySection>
					)}
					{licenseForm.watch("authorized_second_person_active") && (
						<SummarySection label="מיופה כוח #2 " icon={<FaIdBadge />}>
							<InfoCell
								label="שם"
								value={licenseForm.watch("authorized_second_person_first_name")}
							/>
							<InfoCell
								label="שם משפחה"
								value={licenseForm.watch("authorized_second_person_last_name")}
							/>
							<InfoCell
								label="ת.ז"
								value={licenseForm.watch("authorized_second_person_id_number")}
							/>
						</SummarySection>
					)}
					{licenseForm.getValues("license_number") &&
						!licenseForm.getValues("is_medical_condition") && (
							<SummarySection label="רישיון" icon={<FaIdBadge />}>
								<InfoCell
									label="רישיון מספר"
									value={licenseForm.getValues("license_number")}
								/>
								<InfoCell
									label="תוקף רישיון"
									value={
										<div className="flex flex-col">
											<span>
												מ - {formatDate(licenseForm.getValues("license_start"))}
											</span>
											<span>
												עד -{" "}
												{formatDate(licenseForm.getValues("license_expiry"))}
											</span>
										</div>
									}
								/>
							</SummarySection>
						)}

					{!!relevantPrescription && (
						<SummarySection label="מרשם" icon={<FaClipboardList />}>
							<InfoCell
								label="מספר מרשם"
								value={relevantPrescription.prescription.number}
							/>
							{!!relevantPrescription.prescription.from_date &&
								!!relevantPrescription.prescription.to_date && (
									<InfoCell
										label="תוקף מרשם"
										value={
											<div className="flex flex-col">
												<span>
													מ -{" "}
													{formatDate(
														relevantPrescription.prescription.from_date,
													)}
												</span>
												<span>
													עד -{" "}
													{formatDate(
														relevantPrescription.prescription.to_date,
													)}
												</span>
											</div>
										}
									/>
								)}

							{!!relevantPrescription?.prescriptionProducts?.length && (
								<InfoCell
									label="סוגים וכמות"
									value={
										<div className="flex flex-col gap-2">
											{relevantPrescription.prescriptionProducts.map(
												(prescriptionProduct, index) => {
													return (
														<span className="flex gap-2" key={index}>
															<span className="px-2 bg-jane-200 whitespace-nowrap rounded-full">
																{prescriptionProduct.type.label}
															</span>
															<span className="px-2 bg-jane-200 whitespace-nowrap rounded-full">
																{prescriptionProduct.category.label}
															</span>
															<span className="px-2 bg-jane-200 whitespace-nowrap rounded-full">
																{prescriptionProduct.grams.label}
															</span>
														</span>
													);
												},
											)}
										</div>
									}
								/>
							)}
						</SummarySection>
					)}

					{deliveryMethod === DELIVERY_METHODS.PICKUP && (
						<SummarySection label="כתובת" icon={<LocationMarkerIcon />}>
							<InfoCell
								className="col-span-full"
								label="כתובת בית המרקחת"
								value={
									<p>
										{store?.city?.heb_name}, {store.street_name}{" "}
										{store.street_num}
									</p>
								}
							/>
							{deliveryData?.day && (
								<InfoCell
									label="מגיע ביום"
									value={formatDate(deliveryData.day)}
								/>
							)}
							{deliveryData?.time?.value && (
								<InfoCell label="מגעיה בשעה" value={deliveryData.time.label} />
							)}
						</SummarySection>
					)}
					{deliveryMethod === DELIVERY_METHODS.DELIVERY && (
						<SummarySection label="כתובת למשלוח" icon={<TruckIcon />}>
							<InfoCell label="עיר" value={deliveryData.city_heb_name} />
							<InfoCell
								label="רחוב"
								value={deliveryData.delivery_street_name}
							/>
							<InfoCell label="בית" value={deliveryData.delivery_street_num} />
							<InfoCell label="כניסה" value={deliveryData.delivery_entrance} />
							<InfoCell label="קומה" value={deliveryData.delivery_floor} />
							<InfoCell label="דירה" value={deliveryData.delivery_apartment} />
						</SummarySection>
					)}
				</div>
				<form className="sticky flex flex-col gap-4 top-[56px] md:top-[64px]">
					<div
						className={`bg-white rounded-md overflow-hidden flex-col hidden ${
							!isWidget && "md:flex"
						}`}
					>
						<CartPreview
							storeId={cart?.storeId}
							deliveryPrice={
								deliveryMethod === DELIVERY_METHODS.DELIVERY
									? deliveryData.delivery_cost
									: undefined
							}
						/>
					</div>
					<SummarySection label="הערות להזמנה" icon={<FaEdit />}>
						<textarea
							rows={3}
							placeholder="הערות להזמנה (אופציונלי)"
							className="mt-1 block w-full col-span-full border rounded-md shadow-sm py-2 px-3 border-gray-300 focus:ring-jane-500 focus:border-jane-500 focus:outline-none sm:text-base focus:ring-1"
							style={{ fontSize: "16px" }}
							{...register("custom_message", { required: false })}
						/>
					</SummarySection>
					<label
						className={`cursor-pointer flex gap-2 items-center ${
							formState.errors?.approve && "text-red-500"
						}`}
					>
						<input
							type="checkbox"
							onClick={e => {
								if (
									e.target.checked &&
									deliveryMethod === DELIVERY_METHODS.DELIVERY
								) {
									signatureMethods.setSignatureModalOpen(true);
								}
							}}
							{...register("approve", { required: true })}
							className={
								formState.errors?.approve && "border-red-500 focus:ring-red-200"
							}
						/>
						{deliveryMethod === DELIVERY_METHODS.PICKUP
							? "אני מאשר/ת איסוף עצמי מבית המרקחת"
							: "אני מייפה את כוחו של בית המרקחת לשנע מוצרים עבורי"}
					</label>

					<Button primary onClick={submitOrder} disabled={loading}>
						<span className="text-base flex items-center gap-2 p-1 md:p-0">
							{loading && <FaSpinner className="animate-spin" />}
							{loading ? "שולחים הזמנה" : "אישור וסיום הזמנה"}
						</span>
					</Button>
				</form>
			</div>
			<SignatureModal
				signatureMethods={signatureMethods}
				onCancel={() => {
					summaryForm.setValue("approve", false);
				}}
			/>
		</>
	);
}

export default SummaryStep;
