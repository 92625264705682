import { AuthContext } from "components/contexts/AuthContext";
import { RichSelect } from "components/onboarding/RichSelect";
import { useContext, useEffect } from "react";
import { useController } from "react-hook-form";

const MedicalConditionSelect = ({ options, control, defaultCondition }) => {
	return (
		<RichSelect
			placeholder={"בחירת התוויה"}
			registerName="medical_condition"
			control={control}
			options={options}
			className="text-start"
		/>
	);
};

export default MedicalConditionSelect;
