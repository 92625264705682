import React, { useContext, useEffect, useState } from "react";
import CodeStep from "./CodeStep";
import { useApi } from "components/hooks/useApi";
import { AuthContext } from "components/contexts/AuthContext";
import { useForm } from "react-hook-form";
import { toastMessage } from "util/common";

//* This component is temporary and should be removed after we move to new design
//* We need it now to verify phone beore order
//* In the future we will verify phone during registration

function VerifyUserPhoneNumber() {
	const api = useApi();
	const { claims, checkLogin } = useContext(AuthContext);
	const [seconds, setSeconds] = useState(30);
	const [canSendAgain, setCanSendAgain] = useState(false);
	const [loading, setLoading] = useState(false);

	const email = claims?.email;

	const otpForm = useForm({ defaultValues: { otp_code: "" } });
	const { getValues } = otpForm;

	const requestPhoneVerification = async () => {
		setSeconds(30);
		setCanSendAgain(false);
		try {
			const res = api.requestPhoneVerification();
		} catch (error) {}
	};

	useEffect(() => {
		let interval = null;
		if (!canSendAgain && seconds > 0) {
			interval = setInterval(() => {
				setSeconds(seconds => seconds - 1);
			}, 1000);
		} else if (seconds === 0) {
			clearInterval(interval);
			setCanSendAgain(true);
		}
		return () => clearInterval(interval);
	}, [canSendAgain, seconds]);

	const verifyCode = async () => {
		if (!email || otpForm.getValues("otp_code") === "") {
			return;
		}
		const otp_code = getValues("otp_code");
		const params = {
			email,
			otp_code,
		};
		setLoading(true);
		try {
			const res = await api.submitPhoneVerification(params);
			if (res.ok) {
				checkLogin();
				toastMessage("המספר אומת בהצלחה", "success");
			} else {
				throw res.error.message;
			}
		} catch (error) {
			toastMessage("קוד אימות לא נכון", "error");
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		requestPhoneVerification();
	}, []);
	return (
		<div className=" mx-auto max-w-lg">
			<CodeStep
				phone={claims.phone_number}
				isVerification
				verifyCodeLogin={verifyCode}
				loading={loading}
				seconds={seconds}
				sendAgain={requestPhoneVerification}
				canSendAgain={canSendAgain}
				loginForm={otpForm}
			/>
		</div>
	);
}

export default VerifyUserPhoneNumber;
