import { OpeningHoursDisplay } from "components/stores/OpeningHoursDisplay";

export function ShopOpeningHours({ store }) {
	if (!store) {
		return null;
	}
	const valWorkDays = store.opening_hours?.workDays || [];
	const valWorkHours = store.opening_hours?.workHours || {};
	const isDayEnabled = d => valWorkDays.indexOf(d.value) !== -1;
	const isWeeklyHours = () => {
		//get tohour and fromhours for 1-5
		const values = Object.values(store.opening_hours?.workHours)
			.filter((_, index) => index < 5)
			.map(item => item[0]);

		// check if same hours for sunday-thursday
		const hasSameValues = values.every(
			item =>
				item.toHour === values[0].toHour &&
				item.fromHour === values[0].fromHour,
		);

		// return null;
		return hasSameValues ? values[0] : null;
	};
	return (
		<div className="text-base">
			<div className="max-w-[150px]">
				{/* {isWeeklyHours() ? (
					<>
						<div className="flex items-start ">
							<div className="text-gray-600 pl-2 lg:text-base sm:text-sm text-xs w-[50px]">
								א&apos;-ה&apos;
							</div>
							<div className="flex items-center lg:text-base sm:text-sm text-xs">
								<div>{store.opening_hours?.workHours[1][0].fromHour}</div>
								<div className="mx-2 text-xs text-gray-600">-</div>
								<div>{store.opening_hours?.workHours[1][0].toHour}</div>
							</div>
						</div>
						{!!isDayEnabled({ label: "ו", value: "6" }) ? (
							<div className="flex items-start ">
								<div className="text-gray-600 pl-2 lg:text-base sm:text-sm text-xs w-[50px]">
									ו&apos;
								</div>
								<div className="flex items-center lg:text-base sm:text-sm text-xs">
									<div>{store.opening_hours?.workHours[6][0].fromHour}</div>
									<div className="mx-2 text-xs text-gray-600">-</div>
									<div>{store.opening_hours?.workHours[6][0].toHour}</div>
								</div>
							</div>
						) : (
							<>
								<div className="text-gray-600 italic">סגור</div>
							</>
						)}
						{!!isDayEnabled({ label: "ש", value: "7" }) ? (
							<div className="flex items-start ">
								<div className="text-gray-600 pl-2 lg:text-base sm:text-sm text-xs w-[50px]">
									ש&apos;
								</div>
								<div className="flex items-center lg:text-base sm:text-sm text-xs">
									<div>{store.opening_hours?.workHours[7][0].fromHour}</div>
									<div className="mx-2 text-xs text-gray-600">-</div>
									<div>{store.opening_hours?.workHours[7][0].toHour}</div>
								</div>
							</div>
						) : (
							<>
								<div className="text-gray-600 italic">סגור</div>
							</>
						)}
					</>
				) : ( */}
				<div className="flex flex-col lg:text-base sm:text-sm text-xs gap-1.5">
					{days.map(d => (
						<div className="flex items-start " key={d.value}>
							<div className="text-gray-600 pl-2 w-[50px]">{d.label}</div>
							{!!isDayEnabled(d) ? (
								<>
									<div>
										{valWorkHours[d.value].map((item, idx) => (
											<div className="flex items-center" key={idx}>
												<div>{item.fromHour}</div>
												<div className="mx-2 text-xs text-gray-600">-</div>
												<div>{item.toHour}</div>
											</div>
										))}
									</div>
								</>
							) : (
								<>
									<div className="text-gray-600 italic">סגור</div>
								</>
							)}
						</div>
					))}
				</div>
				{/* )} */}
			</div>
		</div>
	);
}
const days = [
	{ label: "א", value: "1" },
	{ label: "ב", value: "2" },
	{ label: "ג", value: "3" },
	{ label: "ד", value: "4" },
	{ label: "ה", value: "5" },
	{ label: "ו", value: "6" },
	{ label: "ש", value: "7" },
];
