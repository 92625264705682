import { Tab } from "@headlessui/react";
import { LocationMarkerIcon, TruckIcon } from "@heroicons/react/outline";
import { Button } from "components/form/Button";
import React, { useEffect, useState } from "react";
import DeliverySettings from "./DeliverySettings";
import PickupSettings from "./PickupSettings";
import { DELIVERY_METHODS } from "util/types";
import { Tooltip } from "react-tippy";
import { StoreShippingRestrictions } from "components/shop/StoreShippingRestrictions";

function DeliveryStep({ checkoutMethods, nextStep: nextStepperStep }) {
	const { DELIVERY, PICKUP } = DELIVERY_METHODS;
	const {
		store,
		storeDeliveryMethods,
		loading,
		deliveryLoading,
		selectedAddressId,
		selfPickUpForm,
		addressForm,
		setDeliveryMethod,
		deliveryMethod,
		submitDelivery,
		getDeliveryData,
		setSelectedAddressId,
	} = checkoutMethods;

	const deliveryData = getDeliveryData();

	const deliveryMethods = [
		{
			label: "איסוף עצמי",
			value: PICKUP,
			icon: <LocationMarkerIcon />,
			disabled: false,
			disableMessage: "אין אפשרות לאיסוף עצמי מהחנות",
		},
		{
			label: "משלוח",
			value: DELIVERY,
			icon: <TruckIcon />,
			disabled: !store?.has_delivery,
			disableMessage: "בית המרקחת אינו מבצע משלוחים",
		},
	];

	const isDisabled = () => {
		if (deliveryLoading) {
			return true;
		}
		if (deliveryMethod === DELIVERY) {
			return !selectedAddressId;
		}
		return false;
	};

	return (
		<div>
			{
				<>
					<Tab.Group
						as="div"
						className="rounded-md"
						defaultIndex={deliveryMethods.findIndex(
							item => item.value === deliveryMethod,
						)}
					>
						<Tab.List className="flex gap-4 p-4 bg-white mb-4 rounded-md">
							{deliveryMethods.map((method, index) => {
								if (method.disabled) {
									return (
										<Tooltip
											title={method.disableMessage}
											placement="top"
											className="w-full"
											arrow
										>
											<Tab
												disabled={method.disabled}
												data-selected={deliveryMethod === method.value}
												onClick={() => setDeliveryMethod(method.value)}
												key={index}
												className={({ selected }) =>
													`w-full rounded-full flex gap-2 items-center justify-center p-1 transition-all  disabled:opacity-70 disabled:cursor-not-allowed ${
														selected
															? "bg-jane-600 text-white"
															: "bg-jane-200 text-black"
													}`
												}
											>
												{!!method.icon && (
													<span className="w-4 h-4">{method.icon}</span>
												)}
												{method.label}
											</Tab>
										</Tooltip>
									);
								} else {
									return (
										<Tab
											disabled={method.disabled}
											data-selected={deliveryMethod === method.value}
											onClick={() => setDeliveryMethod(method.value)}
											key={index}
											className={({ selected }) =>
												`w-full rounded-full flex gap-2 items-center justify-center p-1 transition-all  disabled:opacity-70 disabled:cursor-not-allowed ${
													selected
														? "bg-jane-600 text-white"
														: "bg-jane-200 text-black"
												}`
											}
										>
											{!!method.icon && (
												<span className="w-4 h-4">{method.icon}</span>
											)}
											{method.label}
										</Tab>
									);
								}
							})}
						</Tab.List>
						<Tab.Panels>
							<Tab.Panel className="bg-white rounded-md p-4 flex flex-col gap-4">
								<p className="my-2">
									בחרת באפשרות איסוף עצמי, להלן פרטי בית המרקחת:
								</p>
								<PickupSettings store={store} formMethods={selfPickUpForm} />
							</Tab.Panel>
							<Tab.Panel>
								<div className="bg-white rounded-md p-4">
									<h3 className="font-semibold text-xl text-center">
										בחירת כתובת
									</h3>
									<DeliverySettings checkoutMethods={checkoutMethods} />
								</div>
								{!!selectedAddressId && (
									<div className="bg-white rounded-md p-4 mt-4">
										<div className="w-full text-right py-1 text-gray-500 text-sm border-gray-300 focus:outline-none focus:ring-jane-500 focus:border-jane-500 rounded-md">
											<StoreShippingRestrictions
												deliveryData={deliveryData}
												loading={deliveryLoading}
											/>
										</div>
									</div>
								)}
							</Tab.Panel>
						</Tab.Panels>
					</Tab.Group>
					<Button
						primary
						className="w-full mt-4"
						disabled={isDisabled()}
						onClick={() => submitDelivery(nextStepperStep)}
					>
						<span className="text-base p-1 md:p-0">המשך לשלב הבא</span>
					</Button>
				</>
			}
		</div>
	);
}

export default DeliveryStep;
