import { CheckCircleIcon, ChevronDownIcon } from "@heroicons/react/solid";
import LeaveReviewCard from "components/account/reviews/LeaveReviewCard";
import { Button } from "components/form/Button";
import { useApi } from "components/hooks/useApi";
import { useIsInIframe } from "components/hooks/useIsInIframe";
import { useUserCarts } from "components/jotai/cartAtom";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { MdOutlineRateReview } from "react-icons/md";

function OrderSuccess({ orderInfo }) {
	const router = useRouter();
	const api = useApi();
	const [notReviewed, setNotReviewed] = useState([]);
	const [noReviewItemsToShow, setNoReviewItemsToShow] = useState(3);
	const [notReviewedLoading, setNotReviewedLoading] = useState(false);
	const isInIframe = useIsInIframe();
	const isWidget = router.asPath.includes("widget");
	const { setWidgetOrderModal } = useUserCarts();
	const slicedNotReviewed = notReviewed.slice(0, noReviewItemsToShow);

	useEffect(() => {
		loadNotReviewed();
	}, []);

	const loadNotReviewed = async () => {
		if (!!notReviewedLoading || isInIframe) {
			return;
		}
		setNotReviewedLoading(true);
		const res = await api.getUserNotReviewedProductsAndBatches();
		if (res.ok) {
			setNotReviewed(res.data.not_reviewed_products);
		}
		setNotReviewedLoading(false);
	};

	return (
		<div className="flex flex-col gap-8 items-center">
			<div className="bg-white rounded-xl mt-8 max-w-lg mx-auto text-center flex flex-col items-center gap-4 p-4">
				<span className="flex flex-col items-center">
					<CheckCircleIcon className="w-20 h-20 text-jane-500" />
					<p className="text-jane-500 font-semibold text-lg">
						ההזמנה נשלחה בהצלחה
					</p>
					{orderInfo.orderNumber && (
						<p className="text-sm text-jane-500 -mt-1">
							מספר הזמנה: {orderInfo.orderNumber}
						</p>
					)}
				</span>
				<p>עדכונים אודות הזמנתכם ישלחו בוואטסאפ ובמייל</p>
				{isWidget ? (
					<Button
						className="w-full"
						primary
						onClick={() => setWidgetOrderModal(false)}
					>
						<span className="text-base p-1 md:p-0 whitespace-nowrap">
							חזרה לחנות
						</span>
					</Button>
				) : (
					<div className="flex w-full gap-4 items-center">
						<Button
							className="w-full"
							onClick={() => {
								router.push("/products");
							}}
						>
							<span className="text-base p-1 md:p-0 whitespace-nowrap">
								המשך לקנות
							</span>
						</Button>
						<Link href="/account/?section=AccountOrders" className="w-full">
							<a rel="noopener noreferrer" className="w-full">
								<Button className="w-full" primary>
									<span className="text-base p-1 md:p-0 whitespace-nowrap">
										הזמנות שלי
									</span>
								</Button>
							</a>
						</Link>
					</div>
				)}
			</div>
			{notReviewed.length !== 0 && (
				<div className="flex max-w-sm flex-col gap-4">
					<span className="flex gap-2 items-center">
						<MdOutlineRateReview className="h-10 w-10 " />
						<span className="flex flex-col">
							<p className="leading-none text-sm -mb-1">מהזמנות הקודמות</p>
							<h3 className="text-xl lg:text-2xl">
								מחכה לביקורת שלך{" "}
								<span className="text-jane-500 text-base">
									({notReviewed.length})
								</span>
							</h3>
						</span>
					</span>

					{slicedNotReviewed.map((item, index) => (
						<LeaveReviewCard
							key={index}
							setIsLoginOpen={() => {}}
							product={item}
							refetch={loadNotReviewed}
							isPrimaryButton={false}
							disableLinks={isWidget}
						/>
					))}
					{noReviewItemsToShow < notReviewed.length && !isWidget && (
						<button
							onClick={() => setNoReviewItemsToShow(prev => prev + 3)}
							className="bg-white col-span-full text-center hover:bg-jane-200 w-fit px-4 py-2 cursor-pointer rounded-md mx-auto border text-jane-500 flex gap-1 items-center"
						>
							טען עוד
							<ChevronDownIcon className="h-4 w-4 " />
						</button>
					)}
				</div>
			)}
		</div>
	);
}

export default OrderSuccess;
