import { CheckIcon } from "@heroicons/react/outline";
import React, { Fragment } from "react";
// this is stepper as in redesign, use it from now on
function Stepper({ steps = [], step = 0, stepProgress = 0, handleStepClick }) {
	return (
		<div className="bg-white rounded-md shadow pt-4 pb-10 px-10">
			<div className="flex justify-between items-center">
				{steps.map((stepItem, index) => (
					<Fragment key={index}>
						{index !== 0 && index < steps.length && (
							<div className="w-full mx-4 h-1 rounded-full bg-jane-200">
								<div
									style={{
										width:
											step >= index
												? "100%"
												: step == index - 1
												? stepProgress + "%"
												: "0%",
									}}
									className={`h-1 rounded-md transition-all bg-jane-500 ${
										step >= index && "bg-jane-500"
									}`}
								/>
							</div>
						)}

						<div
							key={index}
							onClick={() => handleStepClick(index)}
							className={`whitespace-nowrap ${step === index ? "font-bold" : ""}
							
							`}
						>
							<span className="rounded-full relative flex justify-center items-center border border-jane-500 h-5 w-5 ">
								{stepItem.completed ? (
									<CheckIcon className="w-5 h-5 p-0.5 text-white rounded-full bg-jane-500" />
								) : step === index ? (
									<span className="w-3 h-3 block rounded-full bg-jane-500" />
								) : null}

								<span className={`absolute -bottom-6 `}>{stepItem.label}</span>
							</span>
						</div>
					</Fragment>
				))}
			</div>
		</div>
	);
}

export default Stepper;
