import { Disclosure } from "@headlessui/react";
import {
	HomeIcon,
	LocationMarkerIcon,
	PlusIcon,
} from "@heroicons/react/outline";
import {
	CheckCircleIcon,
	InformationCircleIcon,
	PencilAltIcon,
	ShoppingCartIcon,
	TruckIcon,
} from "@heroicons/react/solid";
import { CacheContext } from "components/contexts/CacheContext";
import CitySuggestionChips from "components/fields/CitySuggestionChips";
import CitySelect from "components/fields/CitySelect";
import CommonField from "components/fields/CommonField";
import EditAddressForm from "components/forms/EditAdressForm";
import { ModalDialog } from "components/modals/ModalDialog";
import InfoCell from "components/ui/InfoCell";
import React, { useContext, useRef } from "react";
import { FaExclamationCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { Tooltip } from "react-tippy";
import { classNames } from "util/common";

function DeliverySettings({ checkoutMethods }) {
	const { allCities } = useContext(CacheContext);

	const {
		store,
		deliveryOptions,
		selectedAddressId,
		setSelectedAddressId,
		userAddressesMethods,
		handleNewAddressCityChange,
		handleUserAddressChange,
		isWidget,
		cityOcrSuggestion,
		setCityOcrSuggestion,
	} = checkoutMethods;

	const {
		addressLoading,
		isAddressModalOpen,
		setIsAddressModalOpen,
		saveAddress,
		addressForm,
		editAddress,
	} = userAddressesMethods;

	const { shipping_districts: shippingDistricts } = store;

	const {
		control,
		register,
		setValue,
		formState: { errors },
	} = addressForm;

	const newAddressRef = useRef();
	const addressesRef = useRef(new Array());

	const filteredCities = allCities.filter(city => {
		if (!shippingDistricts || !shippingDistricts.length) {
			return city;
		}
		// Check if the city's district exists in options
		const districtExists = shippingDistricts.some(
			district => district.district === city.district,
		);

		// If the district exists in options, check if the city is not in not_shipping_cities
		if (districtExists) {
			const option = shippingDistricts.find(
				option => option.district === city.district,
			);
			const notShippingCitiesIds = option.not_shipping_cities?.map(
				city => city.id,
			);
			return !notShippingCitiesIds.includes(city.id);
		}

		return false;
	});

	function handleClosingOthers(index) {
		let otherRefs = addressesRef?.current;
		if (index !== "other") {
			if (newAddressRef.current.getAttribute("data-open") === "true") {
				newAddressRef.current.setAttribute("data-initiated-by", "code");
				newAddressRef.current.click();
			}
			otherRefs = addressesRef?.current.filter(
				(_, refIndex) => refIndex !== index,
			);
		}
		otherRefs.forEach(ref => {
			const isOpen = ref.getAttribute("data-open") === "true";
			if (isOpen) {
				ref.setAttribute("data-initiated-by", "code");
				ref.click();
			}
		});
	}

	const handleAddressSave = async () => {
		await saveAddress();
	};

	const handleDisclosureClick = async (e, index) => {
		if (index === "other") {
			const initiatedBy =
				newAddressRef.current.getAttribute("data-initiated-by");
			if (initiatedBy === "code") {
				newAddressRef.current.setAttribute("data-initiated-by", "click");
				return;
			}
			if (initiatedBy === "click") {
				if (newAddressRef.current.getAttribute("data-open") === "true") {
					e.preventDefault();
					return;
				} else {
					setSelectedAddressId(index);
					handleClosingOthers(index);
				}
			}
		} else {
			const initiatedBy =
				addressesRef?.current[index]?.getAttribute("data-initiated-by");
			if (initiatedBy === "code") {
				addressesRef.current[index].setAttribute("data-initiated-by", "click");
				return;
			}
			if (initiatedBy === "click") {
				if (addressesRef.current[index].getAttribute("data-open") === "true") {
					e.preventDefault();
					return;
				} else {
					handleUserAddressChange(
						addressesRef.current[index].getAttribute("data-id"),
					);
					handleClosingOthers(index);
				}
			}
		}
	};
	return (
		<div className="flex flex-col gap-2">
			{deliveryOptions?.map((address, index) => (
				<Disclosure
					as="div"
					className="border-b"
					key={index}
					defaultOpen={selectedAddressId === address?.id}
				>
					{({ open }) => (
						<>
							<Disclosure.Button
								as="button"
								ref={element => addressesRef.current.push(element)}
								data-open={open}
								data-id={address?.id}
								data-initiated-by="click"
								key={index}
								className={classNames(
									"flex gap-4 w-full p-1 items-center rounded-md disabled:cursor-not-allowed disabled:bg-jane-50",
									open && "bg-jane-200",
								)}
								onClick={e => {
									handleDisclosureClick(e, index);
								}}
							>
								<span className="flex gap-2 items-center">
									{!address.isDeliveryActive ? (
										<span className="relative">
											<TruckIcon className="w-5 h-5 text-jane-500" />
											<MdCancel className="w-2.5 h-2.5 text-red-700 bg-white rounded-full absolute top-0 left-0" />
										</span>
									) : !address.isConditionMet ? (
										<span className="relative">
											<ShoppingCartIcon className="w-5 h-5 text-jane-500" />
											<FaExclamationCircle className="w-2.5 h-2.5 text-yellow-600 rounded-full bg-white absolute top-0 left-0" />
										</span>
									) : open ? (
										<CheckCircleIcon className="w-5 h-5 text-jane-500" />
									) : address?.main ? (
										<HomeIcon className="w-5 h-5 text-jane-500" />
									) : (
										<LocationMarkerIcon className="w-5 h-5 text-jane-500" />
									)}
									{address?.city_heb_name} - {address?.street_name}{" "}
									{address?.street_num}
								</span>
							</Disclosure.Button>
							<Disclosure.Panel className="bg-jane-200 mt-2 mb-4 grid grid-cols-3 gap-4 p-4 rounded-md relative">
								<span
									className="w-6 h-6 absolute text-jane-700 left-2 top-2 p-1 hover:bg-jane-300 rounded-sm cursor-pointer"
									onClick={() => editAddress(address)}
								>
									<PencilAltIcon />
								</span>
								<InfoCell label="עיר" value={address?.city_heb_name} />
								<InfoCell label="רחוב" value={address?.street_name} />
								<InfoCell label="בית" value={address?.street_num} />
								<InfoCell label="כניסה" value={address?.entrance} />
								<InfoCell label="קומה" value={address?.floor} />
								<InfoCell label="דירה" value={address?.apartment} />
							</Disclosure.Panel>
						</>
					)}
				</Disclosure>
			))}
			<Disclosure
				as="div"
				defaultOpen={selectedAddressId === "other" || !deliveryOptions.length}
			>
				{({ open }) => (
					<>
						<Disclosure.Button
							ref={newAddressRef}
							data-open={open}
							data-id={"other"}
							data-initiated-by="click"
							onClick={e => {
								handleDisclosureClick(e, "other");
							}}
							className={classNames(
								"flex flex-col w-full p-1 rounded-md sm:flex-row sm:items-center sm:gap-2",
								open && "bg-jane-200",
							)}
						>
							<span className="flex gap-2 items-center">
								{open ? (
									<CheckCircleIcon className="w-5 h-5 text-jane-500" />
								) : (
									<PlusIcon className="w-5 h-5 text-jane-500" />
								)}
								כתובת חדשה
							</span>
						</Disclosure.Button>
						<Disclosure.Panel>
							<form className="grid gap-x-4 mt-2 mb-4 gap-y-4 grid-cols-2">
								<div>
									<label className="flex items-center gap-2">
										עיר / ישוב
										<span
											className={`text-jane-400 text-sm hidden ${
												!isWidget && "md:inline"
											}`}
										>
											מציג את הערים שאליהן זמין משלוח מבית מרקחת זה
										</span>
										{
											<Tooltip
												title="מציג את הערים שאליהן זמין משלוח מבית מרקחת זה"
												placement="top"
												arrow
											>
												<InformationCircleIcon
													className={`w-4 h-4 text-jane-700 cursor-pointer ${
														!isWidget && "md:hidden"
													}`}
												/>
											</Tooltip>
										}
									</label>
									<CitySelect
										control={control}
										required
										errors={errors}
										options={filteredCities.map(c => ({
											label: c.heb_name,
											id: c.id,
											district: c.district,
										}))}
										hideLabel
										onChangeProp={e => {
											handleNewAddressCityChange(e);
										}}
									/>
									<CitySuggestionChips
										suggestion={cityOcrSuggestion}
										onClick={city => {
											setValue("city", city);
											setCityOcrSuggestion(null);
										}}
									/>
								</div>

								<CommonField
									register={register}
									registerName="street_name"
									label="רחוב"
									placeholder="רחוב"
									required
									errors={errors}
								/>
								<CommonField
									register={register}
									registerName="street_num"
									label="מספר בית"
									placeholder="מספר בית"
									required
									errors={errors}
								/>
								<CommonField
									register={register}
									registerName="entrance"
									label="כניסה"
									placeholder="כניסה"
									errors={errors}
								/>
								<CommonField
									register={register}
									registerName="floor"
									label="קומה"
									placeholder="קומה"
									errors={errors}
								/>
								<CommonField
									register={register}
									registerName="apartment"
									label="דירה"
									placeholder="דירה"
									errors={errors}
								/>
								<span className="col-span-full">
									<label className="flex gap-2 items-center w-fit">
										<input type="checkbox" {...register("saveAddress")} />
										לשמור כתובת להזמנות הבאות
									</label>
								</span>
							</form>
						</Disclosure.Panel>
					</>
				)}
			</Disclosure>
			{isAddressModalOpen && (
				<ModalDialog
					isLoading={addressLoading}
					isOpen={isAddressModalOpen}
					title="עריכת כתובת"
					onSubmit={handleAddressSave}
					onClose={() => {
						setIsAddressModalOpen(false);
					}}
					cancelBtnText="סגירה"
					closeOnClickOutside={true}
				>
					<EditAddressForm methods={userAddressesMethods} />
				</ModalDialog>
			)}
		</div>
	);
}

export default DeliverySettings;
