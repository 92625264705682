import { CacheContext } from "components/contexts/CacheContext";
import CitySelect from "components/fields/CitySelect";
import CommonField from "components/fields/CommonField";
import React, { useContext } from "react";

function EditAddressForm({ methods }) {
	const { addressForm } = methods;
	const { allCities } = useContext(CacheContext);

	const {
		register,
		control,
		formState: { errors },
	} = addressForm;
	return (
		<div className="grid grid-cols-2 md:grid-cols-3 gap-4">
			<CitySelect
				control={control}
				required
				errors={errors}
				options={allCities.map(c => ({
					label: c.heb_name,
					id: c.id,
					district: c.district,
				}))}
			/>
			<CommonField
				register={register}
				registerName="street_name"
				label="רחוב"
				placeholder="רחוב"
				required
				errors={errors}
			/>
			<CommonField
				register={register}
				registerName="street_num"
				label="מספר בית"
				placeholder="מספר בית"
				required
				errors={errors}
			/>
			<CommonField
				register={register}
				registerName="entrance"
				label="כניסה"
				placeholder="כניסה"
				errors={errors}
			/>
			<CommonField
				register={register}
				registerName="floor"
				label="קומה"
				placeholder="קומה"
				errors={errors}
			/>
			<CommonField
				register={register}
				registerName="apartment"
				label="דירה"
				placeholder="דירה"
				errors={errors}
			/>
			<span className="col-span-full">
				<label className="flex gap-2 items-center w-fit">
					<input type="checkbox" {...register("main")} />
					הגדר כברירת מחדל
				</label>
			</span>
		</div>
	);
}

export default EditAddressForm;
