import { useEffect, useState } from "react";

export function useStepper(stepsArr = []) {
	const [steps, setSteps] = useState(stepsArr);
	const [step, setStep] = useState(0);

	const nextStep = () => {
		setStepCompleted(step);
		setStep(prevStep => prevStep + 1);
	};

	const setStepCompleted = step => {
		setSteps(prevSteps =>
			prevSteps.map((s, index) => {
				if (index === step) {
					return { ...s, completed: true };
				}
				return s;
			}),
		);
	};

	const setStepUncompleted = step => {
		setSteps(prevSteps =>
			prevSteps.map((s, index) => {
				if (index === step) {
					return { ...s, completed: false };
				}
				return s;
			}),
		);
	};

	const prevStep = () => setStep(step - 1);

	const handleStepClick = async index => {
		if (index >= 0 && index < stepsArr.length && index !== step) {
			const currentStep = stepsArr[step];
			if (currentStep?.onStepLeave) {
				const canLeave = await currentStep.onStepLeave();
				if (canLeave) {
					setStepCompleted(step);
					setStep(index);
				} else {
					setStepUncompleted(step);
				}
			} else {
				setStep(index);
			}
		}
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, [step]);

	return {
		step,
		steps,
		setSteps,
		setStep,
		nextStep,
		prevStep,
		handleStepClick,
	};
}
