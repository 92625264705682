import { InformationCircleIcon } from "@heroicons/react/outline";
import React from "react";

function InfoBox({ text, className }) {
	return (
		<div
			className={
				"bg-jane-200 border rounded-md p-2 border-jane-500 text-jane-700 " +
				className
			}
		>
			<span className="flex gap-1 items-center font-semibold">
				<InformationCircleIcon className="w-5 h-5" /> הודעה:
			</span>
			{text}
		</div>
	);
}

export default InfoBox;
