import { forwardRef, useState } from "react";
import { BiEraser } from "react-icons/bi";
import { FaSpinner } from "react-icons/fa";
import ReactSignatureCanvas from "react-signature-canvas";
import { classNames } from "util/common";

const Signature = forwardRef((props, ref) => {
	const {
		width,
		height,
		className,
		clearOnResize = true,
		clear,
		handleEnd,
		signatureUrl,
		previewUrl,
	} = props;

	const [isLoading, setIsLoading] = useState(true);
	return (
		<div className="flex flex-col gap-3">
			<div className="flex box-border flex-col items-center flex-wrap gap-1">
				{signatureUrl && previewUrl ? (
					<span className="relative">
						<img
							src={previewUrl}
							alt="user signature"
							className={`h-[250px] bg-white w-[300px] border border-jane-500 rounded-md ${
								isLoading && "animate-pulse flex items-center justify-center"
							}`}
							onLoad={() => {
								setIsLoading(false);
							}}
						/>
						{isLoading && (
							<div className="absolute inset-0 w-full h-full bg-jane-200 bg-opacity-60 flex items-center justify-center">
								<FaSpinner className="w-10 h-10 animate-spin text-jane-500" />
							</div>
						)}
					</span>
				) : (
					<ReactSignatureCanvas
						ref={ref}
						canvasProps={{
							width: width || 300,
							height: height || 250,
							className: classNames(
								"h-[250px] rounded-md mx-auto border border-jane-500 bg-white grow",
								className,
							),
						}}
						clearOnResize={clearOnResize}
						onEnd={handleEnd}
					/>
				)}

				<span
					type="button"
					onClick={clear}
					className="p-2 underline flex gap-1 items-center cursor-pointer hover:bg-jane-200 rounded-md text-center"
				>
					<BiEraser className="h-5 w-5" />
					{!!signatureUrl ? "לחתום מחדש" : "איפוס חתימה"}
				</span>
			</div>
		</div>
	);
});
Signature.displayName = "Signature";
export default Signature;
