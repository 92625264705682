import { GiftIcon } from "@heroicons/react/outline";
import React from "react";

function DiscountBadge({ productId, discounts }) {
	if (!discounts?.length) return;
	const discount = discounts.find(discount => {
		return discount?.discounted_products?.some(discountedProduct => {
			return discountedProduct.product_id === productId;
		});
	});

	if (!discount) return;

	if (
		discount.one_time_discount &&
		discount.cheapest_discounted_product.product_id === productId
	) {
		return (
			<div className="rounded-md p-1 px-2 w-fit bg-jane-200 flex gap-2 items-center text-sm leading-none">
				<GiftIcon className="w-4 h-4 text-red-500" />
				{discount.title}
			</div>
		);
	}

	return (
		<div className="rounded-md p-1 px-2 w-fit bg-jane-200 flex gap-2 items-center text-sm leading-none">
			<GiftIcon className="w-4 h-4 text-red-500" />
			{discount.title}
		</div>
	);
}

export default DiscountBadge;
