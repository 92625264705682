import { GiftIcon } from "@heroicons/react/outline";
import { TruckIcon } from "@heroicons/react/solid";
import CartProductPrice from "components/cart/CartProductPrice";
import DiscountBadge from "components/cart/DiscountBadge";
import { checkoutCartAtom } from "components/jotai/checkoutCartAtom";
import CustomMessage from "components/shop/CustomMessage";
import BreakLine from "components/ui/BreakLine";
import ProductImage from "components/ui/ProductImage";
import { useAtom } from "jotai";
import React, { Fragment } from "react";
import { MdOutlineImage } from "react-icons/md";
import { formatPrice, productAlt, logoAlt } from "util/common";

function CartPreview({ deliveryPrice, className = "" }) {
	const [cart, setCart] = useAtom(checkoutCartAtom);
	const {
		discountAmount,
		totalPrice: totalPriceWithoutDelivery,
		totalItems,
	} = cart;

	const totalPrice = !!deliveryPrice
		? totalPriceWithoutDelivery + deliveryPrice
		: totalPriceWithoutDelivery;

	const store = cart?.store;
	const products = cart?.products;

	return (
		<div className={"rounded-md bg-jane-200  " + className}>
			<div className="flex gap-2 items-center p-4 text-lg font-semibold">
				{!!store?.logo_url ? (
					<img
						src={store.logo_url}
						alt={logoAlt(store)}
						className="rounded-full w-9 h-9"
					/>
				) : (
					<MdOutlineImage className="text-jane-400 h-9 w-9" />
				)}

				{store.display_name}
			</div>
			<div className="bg-white w-full p-4 flex flex-col gap-4">
				{!!store?.custom_message && (
					<CustomMessage message={store?.custom_message} />
				)}
				{products?.map(product => {
					return (
						<Fragment key={product.id}>
							<div className="flex gap-2">
								<ProductImage
									product={product.product}
									className="w-16 h-16 rounded-md"
								/>
								<div className="flex flex-col w-full">
									<p className="font-semibold text-lg">
										{product.product.heb_name}
									</p>
									{!!product?.batch?.batch_id && (
										<p className="text-jane-500 text-xs">
											{product.batch.batch_id}
										</p>
									)}
									{product?.category && <p>{product.category}</p>}
									<DiscountBadge
										productId={product.id}
										discounts={cart.discounts}
									/>
								</div>
								<div className="flex flex-col justify-between items-end">
									<span className="whitespace-nowrap">
										{product.count} יח&apos;
									</span>
									<span className="text-end">
										<CartProductPrice
											product={product}
											discounts={cart.discounts}
										/>
									</span>
								</div>
							</div>
							<BreakLine />
						</Fragment>
					);
				})}
				<span className="self-end w-full flex flex-col text-end justify-end text-jane-700">
					<div className="flex justify-between items-center font-semibold text-base gap-1">
						<p>סך הכל מוצרים:</p>
						<p>{totalItems * 10} גרם</p>
					</div>
					{(!!discountAmount || !!deliveryPrice || deliveryPrice == 0) && (
						<div className="flex justify-between items-center font-semibold text-base  gap-1">
							{!!discountAmount && (!!deliveryPrice || deliveryPrice == 0) ? (
								<p>מחיר לפני הנחות ומשלוח:</p>
							) : !discountAmount && (!!deliveryPrice || deliveryPrice == 0) ? (
								<p>מחיר לפני משלוח:</p>
							) : !!discountAmount && !deliveryPrice ? (
								<p>מחיר לפני הנחות:</p>
							) : (
								<p>סה&quot;כ:</p>
							)}
							<p>
								₪
								{!!discountAmount
									? formatPrice(totalPriceWithoutDelivery + discountAmount)
									: formatPrice(totalPriceWithoutDelivery)}
							</p>
						</div>
					)}
					{!!discountAmount && (
						<div className="flex justify-between items-center font-semibold text-base  gap-1">
							<p className="flex gap-1 items-center">
								<GiftIcon className="h-4 w-4" /> הנחות:
							</p>
							<p className="text-red-500 ">₪{formatPrice(discountAmount)}-</p>
						</div>
					)}
					{(!!deliveryPrice || deliveryPrice == 0) && (
						<div className="flex justify-between items-center font-semibold text-base  gap-1">
							<p className="flex gap-1 items-center">
								<TruckIcon className="h-4 w-4" /> עלות משלוח:
							</p>
							{deliveryPrice == 0 ? (
								<p>חינם</p>
							) : (
								<p>₪{formatPrice(deliveryPrice)}</p>
							)}
						</div>
					)}
				</span>
				<BreakLine />
				<div className="flex justify-between items-center font-semibold text-lg gap-1">
					<p className="flex gap-1 items-center">סך הכל לתשלום:</p>
					<p className="underline">₪{formatPrice(totalPrice)}</p>
				</div>
			</div>
		</div>
	);
}

export default CartPreview;
