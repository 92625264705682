import { CacheContext } from "components/contexts/CacheContext";
import { FieldError } from "components/form/FieldError";
import React, { useContext } from "react";
import { useController } from "react-hook-form";
import Select from "react-select";

function CitySelect({
	control,
	registerName = "city",
	required = false,
	label = "עיר / ישוב",
	hideLabel = false,
	options,
	onChangeProp = () => {},
}) {
	const cache = useContext(CacheContext);

	const {
		field: { onChange, value },
		fieldState: { error },
	} = useController({
		name: registerName,
		control,
		rules: { required: required && "שדה חובה" },
	});
	const styles = {
		control: (base, state) => {
			return {
				...base,
				transition: "all .2s ease",
				minHeight: 20,
				paddingLeft: 0,
				paddingRight: 0,
				cursor: "pointer",
				border: error
					? "solid 1px #fca5a5"
					: state.menuIsOpen
					? "solid 1px #64748b"
					: "solid 1px rgb(209, 213, 219)",
				boxShadow: state.menuIsOpen
					? "0 0 0 1px #64748b"
					: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
				backgroundColor: error ? "#fef8f8" : "#fff",
				padding: "8px 12px",

				"&:hover": {
					border: error
						? "solid 1px #fca5a5"
						: state.menuIsOpen
						? "solid 1px #64748b"
						: "solid 1px rgb(209, 213, 219)",
				},
				"*": {
					boxShadow: "none !important",
				},
			};
		},

		multiValue: provided => ({
			...provided,
			borderRadius: "8px",
			margin: "3px 5px",
			padding: "0px 2px",
		}),
		input: provided => ({
			...provided,
			margin: "0px",
			outline: "none",
			padding: "0px",
			border: "red",
		}),
		indicatorsContainer: provided => ({
			...provided,
			height: "auto",
			paddingTop: 0,
			paddingBottom: 0,
			display: "flex",
			borderRight: "none",
		}),
		indicatorSeparator: provided => ({
			...provided,
			display: "none",
		}),
		valueContainer: provided => ({
			...provided,
			padding: 0,
			display: "flex",
		}),
		dropdownIndicator: provided => ({
			...provided,
			paddingTop: 0,
			paddingBottom: 0,
			paddingLeft: 4,
			paddingRight: 4,
			transform: "scale(0.75)",
		}),
		menu: provided => ({
			...provided,
			minWidth: 80,
		}),
		option: (base, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...base,
				cursor: "pointer",
				height: "auto",
				minHeight: 0,
				padding: "6px 12px",
				fontWeight: 400,
				background: isDisabled
					? "#fff"
					: isFocused
					? "rgba(0,0,0,0.025)"
					: "none",
				color: isDisabled ? "#D1D5DB" : isFocused ? "#1F2937" : "#374151",
				"&:active": {
					background: isDisabled
						? "#fff"
						: isFocused
						? "rgba(0,0,0,0.025)"
						: "none",
					color: isDisabled ? "#D1D5DB" : isFocused ? "#1F2937" : "#374151",
				},
			};
		},
	};
	return (
		<div>
			{!hideLabel && <label htmlFor={registerName}>{label}</label>}
			<Select
				id={registerName}
				className="mt-1"
				isClearable={false}
				isSearchable={true}
				options={
					options ||
					cache.allCities.map(c => ({
						label: c.heb_name,
						id: c.id,
						district: c.district,
					}))
				}
				value={value}
				styles={styles}
				onChange={e => {
					onChange(e);
					onChangeProp(e);
				}}
				placeholder={"עיר / ישוב"}
				noOptionsMessage={() => "לא נמצאו פריטים."}
			/>
			{error && <FieldError message={error.message} />}
		</div>
	);
}

export default CitySelect;
