import { DateInput } from "components/form/DateInput";
import { RichSelect } from "components/onboarding/RichSelect";
import { ShopOpeningHours } from "components/shop/ShopOpeningHours";
import InfoBox from "components/ui/InfoBox";
import moment from "moment";
import React from "react";
import { logoAlt } from "util/common";
import { ARRIVE_AT_OPTIONS } from "util/types";

function PickupSettings({ store, formMethods }) {
	const {
		register,
		control,
		watch,
		formState: { errors },
	} = formMethods;
	const today = moment().format("YYYY-MM-DD"); // Get today's date in YYYY-MM-DD format

	return (
		<>
			<div className="flex flex-col sm:flex-row gap-4">
				<div className="flex w-full items-center gap-2">
					<img
						src={store?.logo_url}
						alt={logoAlt(store)}
						className="w-16 h-16 rounded-full"
					/>
					<p className="text-xl font-semibold">{store?.display_name}</p>
				</div>
				{store?.custom_message && (
					<InfoBox className="w-full" text={store?.custom_message} />
				)}
			</div>
			<span>
				<p className="font-semibold">כתובת</p>
				<p>
					{store?.city?.heb_name}, {store?.street_name} {store?.street_num}
				</p>
			</span>
			<div className="flex gap-4 flex-col sm:flex-row">
				<span className="w-full">
					<p className="font-semibold">שעות פתיחה</p>
					{/* todo: fix this goofy component */}
					<ShopOpeningHours store={store} />
				</span>
				<span className="w-full">
					<p className="font-semibold">אני מתכנן להגיע בתאריך ובשעה</p>

					<form className="flex gap-4  h-fit">
						<DateInput
							register={register}
							name="day"
							className="w-1/2 h-10"
							formOptions={{
								required: watch("time") && "שדה חובה",
							}}
							min={today}
							errors={errors}
							placeholder="תאריך"
						/>
						<RichSelect
							control={control}
							registerName="time"
							options={ARRIVE_AT_OPTIONS}
							className="w-1/2"
							placeholder="שעה"
						/>
					</form>
				</span>
			</div>
		</>
	);
}

export default PickupSettings;
