import StepDisclosure from "components/ui/StepDisclosure";
import React from "react";
import LicenseChekcout from "./LicenseCheckout";
import IdCheckout from "./IdCheckout";
import PrescriptionCheckout from "./PrescriptionCheckout";
import { CHECKOUT_STEPS } from "util/types";
import { Button } from "components/form/Button";

function DocumentsStep({ checkoutMethods, nextStep: nextStepperStep }) {
	const { USER_INFO, LICENSE, PRESCRIPTION } = CHECKOUT_STEPS;
	const { nextStep, setStep, step, disclosureConfig, validateForm } =
		checkoutMethods;
	return (
		<>
			<div className="flex flex-col gap-2 md:gap-4">
				<StepDisclosure
					{...disclosureConfig[USER_INFO]}
					setStep={setStep}
					currentStep={step}
					nextStep={nextStep}
					validateForm={validateForm}
				>
					<IdCheckout {...checkoutMethods} />
				</StepDisclosure>
				<StepDisclosure
					{...disclosureConfig[LICENSE]}
					setStep={setStep}
					currentStep={step}
					nextStep={nextStep}
					validateForm={validateForm}
				>
					<LicenseChekcout {...checkoutMethods} />
				</StepDisclosure>
				<StepDisclosure
					{...disclosureConfig[PRESCRIPTION]}
					setStep={setStep}
					currentStep={step}
					nextStep={nextStep}
					validateForm={validateForm}
				>
					<PrescriptionCheckout
						checkoutMethods={checkoutMethods}
						nextStep={nextStepperStep}
					/>
				</StepDisclosure>
				<Button
					primary
					onClick={() => {
						if (
							step === PRESCRIPTION &&
							disclosureConfig[USER_INFO].completed &&
							disclosureConfig[LICENSE].completed
						) {
							nextStep(nextStepperStep);
						} else {
							nextStep();
						}
					}}
					className="sticky z-10 bottom-3 !text-[16px] md:hidden"
				>
					<span className="py-2  md:py-0">
						{step === USER_INFO
							? "המשך לרישיון"
							: step === LICENSE
							? "המשך למרשם"
							: "המשך לשלב הבא "}
					</span>
				</Button>
			</div>
		</>
	);
}

export default DocumentsStep;
