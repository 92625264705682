import { CacheContext } from "components/contexts/CacheContext";
import React, { useContext } from "react";

function CitySuggestionChips({ suggestion = "", onClick }) {
	const { allCities } = useContext(CacheContext);
	const citiesOptions = allCities.filter(city =>
		city.heb_name.includes(suggestion),
	);

	const handleClick = e => {
		const selectedCity = allCities.find(
			city => city.heb_name === e.target.innerText,
		);
		onClick({
			label: selectedCity.heb_name,
			id: selectedCity.id,
			district: selectedCity.district,
		});
	};

	if (!suggestion) return null;

	return (
		<div className="w-full pb-1 flex gap-1 overflow-x-auto custom-scroll">
			הצעות:
			{citiesOptions.map((city, index) => (
				<span
					key={index}
					onClick={handleClick}
					className="bg-jane-500 leading-none flex items-center whitespace-nowrap rounded-full cursor-pointer text-sm text-white px-2 transition-all hover:bg-jane-600"
				>
					{city.heb_name}
				</span>
			))}
		</div>
	);
}

export default CitySuggestionChips;
