import { useCallback, useContext, useEffect, useState } from "react";
import { useApi } from "./useApi";
import { CHECKOUT_STEPS } from "util/types";
import { AuthContext } from "components/contexts/AuthContext";
import { useForm } from "react-hook-form";
import { FaClipboardList, FaIdBadge, FaNotesMedical } from "react-icons/fa";
import { clearTemporaryLinks, toastMessage } from "util/common";
import useSignature from "./useSignature";
import { useRouter } from "next/router";
import { useUserCarts } from "components/jotai/cartAtom";
import { checkoutCartAtom } from "components/jotai/checkoutCartAtom";
import { useAtom } from "jotai";
import usePrescriptions from "./usePrescriptions";
import useOcr from "./useOcr";
import useLicense from "./useLicense";
import { AnalyticsContext } from "components/contexts/AnalyticsProvider";
import useLocalStorage from "./useLocalStorage";
import { listProductTypes } from "util/types";
import { COUNTRIES } from "util/countryListIcons";
import useStoreDelivery from "./useStoreDelivery";
import useUserAddresses from "./useUserAddresses";
import { ARRIVE_AT_OPTIONS } from "util/types";
import { DELIVERY_METHODS } from "util/types";
import useDownload, { FILE_NAMES } from "./useDownload";
import { CacheContext } from "components/contexts/CacheContext";
import { watch } from "fs";

const useCheckout = () => {
	const router = useRouter();
	const { removeCart } = useUserCarts();
	const [cart, setCart] = useAtom(checkoutCartAtom);
	const { DELIVERY, PICKUP } = DELIVERY_METHODS;
	const store = cart?.store;
	const storeId = store?.id;
	const { USER_INFO, LICENSE, PRESCRIPTION } = CHECKOUT_STEPS;
	const { collectAnalytics, collectAnalyticsGoogle } =
		useContext(AnalyticsContext);
	const cache = useContext(CacheContext);
	//* HOOKS
	const api = useApi();
	const { claims, authenticated, checkLogin } = useContext(AuthContext);
	const { saveToLocalStorage, getFromLocalStorage, clearLocalStorage } =
		useLocalStorage(`j-user`);
	const { getFileUrls } = useDownload();
	const userAddressesMethods = useUserAddresses();
	const signatureMethods = useSignature();
	const licenseMethods = useLicense();
	const prescriptionMethods = usePrescriptions();
	const storeDeliveryMethods = useStoreDelivery(storeId);
	const selfPickUpForm = useForm();

	const {
		getUserDeliveryAddressesByCart,
		getDeliveryInfoByCityId,
		getCartDeliveryInfoByCityId,
		loading: deliveryLoading,
	} = storeDeliveryMethods;

	const { userAddresses, loadUserAddresses, createUserAddress, addressForm } =
		userAddressesMethods;

	const {
		licenseForm,
		handleLicenseSave,
		insertLicenseOcrData,
		updatedLicenseFields,
		constructLicenseOcrData,
	} = licenseMethods;

	const {
		prescriptionForm,
		getRelevantPrescription,
		handlePrescriptionSave,
		updatedPrescriptionFields,
		getPrescriptionsData,
		sortPrescriptionsByDate,
		constructPrescriptionOcrData,
		insertPrescriptionOcrData,
	} = prescriptionMethods;

	const { updatedFields, setUpdatedFields, updateField } = useOcr();

	const isWidget = router.asPath.includes("widget/");
	const [step, setStep] = useState(USER_INFO);
	const [loading, setLoading] = useState(false);
	const [deliveryOptions, setDeliveryOptions] = useState(userAddresses);
	const [discount, setDiscount] = useState(null);
	const [orderInfo, setOrderInfo] = useState({ submitted: false });
	const [selectedAddressId, setSelectedAddressId] = useState("other");
	const [deliveryMethod, setDeliveryMethod] = useState(PICKUP);
	const [cityOcrSuggestion, setCityOcrSuggestion] = useState("");

	const idForm = useForm({
		defaultValues: {
			first_name: claims?.first_name || "",
			last_name: claims?.last_name || "",
			id_number: claims?.id_number || "",
		},
	});

	const summaryForm = useForm();

	const [disclosureConfig, setDisclosureConfig] = useState({
		[USER_INFO]: {
			number: 1,
			completed: false,
			isOpen: step === USER_INFO,
			disabled: false,
			title: "תעודת זהות",
			icon: <FaIdBadge />,
			step: USER_INFO,
		},
		[LICENSE]: {
			number: 2,
			completed: false,
			isOpen: step === LICENSE,
			disabled: true,
			title: "רישיון",
			icon: <FaNotesMedical />,
			step: LICENSE,
		},
		[PRESCRIPTION]: {
			number: 3,
			completed: false,
			isOpen: step === PRESCRIPTION,
			disabled: true,
			title: "מרשם",
			icon: <FaClipboardList />,
			step: PRESCRIPTION,
		},
	});

	const completeStep = step => {
		setDisclosureConfig(prevConfig => ({
			...prevConfig,
			[step]: {
				...prevConfig[step],
				completed: true,
			},
		}));
	};

	const uncompleteStep = step => {
		setDisclosureConfig(prevConfig => ({
			...prevConfig,
			[step]: {
				...prevConfig[step],
				completed: false,
			},
		}));
	};

	const validateForm = async stepProp => {
		const currentStep = stepProp || step;
		let isValid = false;
		try {
			if (currentStep === USER_INFO) {
				await idForm.handleSubmit(data => {
					isValid = true;
				})();
			}

			if (currentStep === LICENSE) {
				await licenseForm.handleSubmit(
					data => {
						isValid = true;
					},
					errors => {
						toastMessage("יש למלא את כל שדות החובה", "error");
					},
				)();
			}

			if (currentStep === PRESCRIPTION) {
				await prescriptionForm.handleSubmit(
					data => {
						isValid = true;
					},
					errors => {
						window.scrollTo({
							top: 280, // prescriptions swiper position
							behavior: "smooth",
						});
						toastMessage("יש למלא את כל שדות החובה", "error");
					},
				)();
			}
			if (isValid) {
				completeStep(currentStep);
			} else {
				uncompleteStep(currentStep);
			}
			return isValid;
		} catch (error) {
			console.error("Form validation error:", error);
			return false;
		}
	};

	const getFormValue = (form, field, fallback) => {
		const res =
			!!form.getValues(field) ||
			form.getValues(field) === false ||
			form.getValues(field) === 0
				? form.getValues(field)
				: fallback;
		return res;
	};

	useEffect(() => {
		const activeOption = deliveryOptions.find(
			option => option?.isConditionMet && option?.isDeliveryActive,
		);

		if (activeOption) {
			setSelectedAddressId(activeOption.id);
		} else {
			setSelectedAddressId("other");
		}
	}, [deliveryOptions]);

	useEffect(() => {
		if (!authenticated || !userAddresses || !cart) {
			return;
		}
		updateUserAddressesWithDeliveryData();
	}, [userAddresses, cart]);

	useEffect(() => {
		if (!isWidget && !!cart && String(storeId) !== String(cart.storeId))
			router.push("/cart"); // Redirect to home page if storeId is not provided
	}, [cart]);

	useEffect(() => {
		if (!cart) {
			if (orderInfo.submitted) {
				return;
			} else {
				if (!isWidget) {
					router.push("/cart");
				}
				return;
			}
		}
		loadStoreInfo();

		const storedClaims = getFromLocalStorage("idFormValues");

		const firstName = !!storedClaims?.first_name
			? storedClaims?.first_name
			: claims?.first_name || "";
		const lastName = !!storedClaims?.last_name
			? storedClaims?.last_name
			: claims?.last_name || "";
		const idNumber = !!storedClaims?.id_number
			? storedClaims?.id_number
			: claims?.id_number || "";

		if (!!storedClaims?.id_url && !!storedClaims?.id_url.length) {
			idForm.setValue("id_url", storedClaims?.id_url);
			idForm.setValue("previewUrl", storedClaims?.previewUrl);
		} else if (claims?.has_id_url) {
			getFileUrls(FILE_NAMES.ID).then(urls => {
				if (!urls) return;
				idForm.setValue("id_url", urls?.url);
				idForm.setValue("previewUrl", urls?.presigned_url);
			});
		} else {
			idForm.setValue("id_url", "");
		}
		idForm.setValue("first_name", firstName);
		idForm.setValue("last_name", lastName);
		idForm.setValue("id_number", idNumber);
	}, [claims]);

	useEffect(() => {
		setDisclosureConfig(prevConfig => ({
			...prevConfig,
			[USER_INFO]: {
				...prevConfig[USER_INFO],
				isOpen: step === USER_INFO,
			},
			[LICENSE]: {
				...prevConfig[LICENSE],
				isOpen: step === LICENSE,
			},
			[PRESCRIPTION]: {
				...prevConfig[PRESCRIPTION],
				isOpen: step === PRESCRIPTION,
			},
		}));
	}, [step]);

	useEffect(() => {
		summaryForm.setValue("approve", false);
	}, [deliveryMethod]);

	const loadStoreInfo = async () => {
		try {
			const res = await api.getStore(storeId);
			if (res.ok) {
				setCart(prevState => {
					return { ...prevState, store: res.data.item };
				});
			} else {
				throw res.error.message;
			}
		} catch (error) {
			console.error(error);
		}
	};

	const nextStep = afterNextStep => {
		switch (step) {
			case USER_INFO:
				idForm.handleSubmit(data => {
					setDisclosureConfig(prevConfig => ({
						...prevConfig,
						[USER_INFO]: {
							...prevConfig[USER_INFO],
							completed: true,
						},
						[LICENSE]: {
							...prevConfig[LICENSE],
							disabled: false,
						},
					}));
					setStep(LICENSE);
					if (typeof afterNextStep === "function") {
						afterNextStep();
					}
				})();
				saveToLocalStorage("idFormValues", idForm.getValues());
				break;
			case LICENSE:
				licenseForm.handleSubmit(
					data => {
						setDisclosureConfig(prevConfig => ({
							...prevConfig,
							[LICENSE]: {
								...prevConfig[LICENSE],
								completed: true,
							},
							[PRESCRIPTION]: {
								...prevConfig[PRESCRIPTION],
								disabled: false,
							},
						}));

						setStep(PRESCRIPTION);
						if (typeof afterNextStep === "function") {
							afterNextStep();
						}
					},
					errors => {
						toastMessage("שגיאה, יש למלא את כל שדות החובה", "error");
					},
				)();
				saveToLocalStorage("licenseForm", licenseForm.getValues());
				break;
			case PRESCRIPTION:
				prescriptionForm.handleSubmit(
					data => {
						setDisclosureConfig(prevConfig => ({
							...prevConfig,
							[PRESCRIPTION]: {
								...prevConfig[PRESCRIPTION],
								completed: true,
							},
						}));
						handleHomeAddress();
						if (typeof afterNextStep === "function") {
							afterNextStep();
						}
					},
					errors => {
						window.scrollTo({
							top: 280, // prescriptions swiper position
							behavior: "smooth",
						});
						toastMessage("שגיאה, יש למלא את כל שדות החובה", "error");
					},
				)();

				saveToLocalStorage("prescriptionForm", prescriptionForm.getValues());
				break;
			default:
				break;
		}
	};

	const updateUserAddressesWithDeliveryData = async () => {
		const deliveryData = await getUserDeliveryAddressesByCart(
			cart,
			userAddresses,
		);
		setDeliveryOptions(deliveryData);
	};

	const submitDelivery = callback => {
		let data = {};
		const handleSubmitComplete = () => {
			if (
				deliveryMethod === "delivery" &&
				(!addressForm.getValues("isDeliveryActive") ||
					!addressForm.getValues("isConditionMet"))
			) {
				toastMessage("אין אפשרות למשלוח לכתובת זו", "error");
			} else {
				callback(data); // Call the callback with the result
				saveToLocalStorage("addressForm", data);
			}
		};

		if (deliveryMethod === "delivery") {
			if (!selectedAddressId) {
				toastMessage("נא לבחור כתובת למשלוח", "error");
				return;
			} else {
				if (selectedAddressId === "other") {
					addressForm.handleSubmit(param => {
						data = {
							address: { ...param },
							address_id: selectedAddressId,
							delivery_method: deliveryMethod,
						};
						handleSubmitComplete();
					})();
				} else {
					const deliveryData = deliveryOptions.find(
						address => address.id === selectedAddressId,
					);
					addressForm.setValue(
						"delivery_cost",
						deliveryData?.eligibleTiers?.[0]?.shipping_cost,
					);
					addressForm.setValue("eligibleTiers", deliveryData.eligibleTiers);
					addressForm.setValue(
						"isDeliveryActive",
						deliveryData.isDeliveryActive,
					);
					addressForm.setValue("deliveryDays", deliveryData.deliveryDays);
					addressForm.setValue("priceTiers", deliveryData.priceTiers);
					addressForm.setValue("isConditionMet", deliveryData.isConditionMet);
					addressForm.setValue(
						"isDeliveryActive",
						deliveryData.isDeliveryActive,
					);
					data = {
						address: deliveryData,
						address_id: selectedAddressId,
						delivery_method: deliveryMethod,
					};
					handleSubmitComplete();
				}
			}
		} else {
			selfPickUpForm.handleSubmit(param => {
				param.time = param?.time?.value;
				data = {
					comeAt: { ...param },
					delivery_method: deliveryMethod,
				};
				handleSubmitComplete();
			})();
		}
	};

	const handleHomeAddress = async () => {
		const cityId = licenseForm.getValues("delivery_city")?.id;
		const street_name = licenseForm.getValues("street_name");
		const street_num = licenseForm.getValues("street_num");
		const apartment = licenseForm.getValues("apartment");
		const isAddressSaved = deliveryOptions.some(
			address =>
				address.city_id == cityId &&
				address.street_name == street_name &&
				address.street_num == street_num &&
				address.apartment == apartment,
		);
		if (!isAddressSaved && !!cityId && !!street_name && !!street_num) {
			await createUserAddress({
				city_id: cityId,
				street_name,
				street_num,
				apartment,
				main: true,
			});
			await loadUserAddresses();
		}
	};

	const getDeliveryData = () => {
		let data = { delivery_type: deliveryMethod };
		const storedValue = getFromLocalStorage("addressForm");
		let tmpDeliveryMethod = !storedValue?.delivery_method
			? deliveryMethod
			: storedValue?.delivery_method;
		if (
			storedValue?.delivery_method === PICKUP &&
			deliveryMethod === DELIVERY
		) {
			tmpDeliveryMethod = deliveryMethod;
		}

		if (tmpDeliveryMethod === PICKUP) {
			const pickUpDay = getFormValue(
				selfPickUpForm,
				"day",
				storedValue?.comeAt?.day,
			);
			const pickUpHour =
				getFormValue(selfPickUpForm, "time")?.value ||
				storedValue?.comeAt?.time;

			if (pickUpHour && pickUpDay) {
				data.pickup_time = `${pickUpDay} ${pickUpHour}:00`;
			} else if (pickUpHour) {
				data.pickup_time = `${pickUpHour}:00`;
			} else if (pickUpDay) {
				data.pickup_time = pickUpDay;
			}
		} else {
			if (selectedAddressId === "other") {
				const storedAddress = storedValue?.address;
				const storedAddressCity = {
					label: !!storedAddress?.city?.label
						? storedAddress?.city?.label
						: storedAddress?.city_heb_name,
					id: !!storedAddress?.city?.id
						? storedAddress?.city?.id
						: storedAddress?.city_id,
					district: !!storedAddress?.city?.district
						? storedAddress?.city?.district
						: storedAddress?.district,
				};
				data = {
					...data,
					city_heb_name:
						getFormValue(addressForm, "city")?.label ||
						storedAddress?.city?.label,
					delivery_city_id:
						getFormValue(addressForm, "city")?.id || storedAddress?.city_id,
					delivery_street_name: getFormValue(
						addressForm,
						"street_name",
						storedAddress?.street_name,
					),
					delivery_street_num: getFormValue(
						addressForm,
						"street_num",
						storedAddress?.street_num,
					),
					delivery_apartment: getFormValue(
						addressForm,
						"apartment",
						storedAddress?.apartment,
					),
					delivery_floor: getFormValue(
						addressForm,
						"floor",
						storedAddress?.floor,
					),
					delivery_entrance: getFormValue(
						addressForm,
						"entrance",
						storedAddress?.entrance,
					),
					delivery_cost: getFormValue(
						addressForm,
						"delivery_cost",
						storedAddress?.eligibleTiers?.[0]?.shipping_cost,
					),
					deliveryDays: getFormValue(
						addressForm,
						"deliveryDays",
						storedAddress?.deliveryDays,
					),
					eligibleTiers: getFormValue(
						addressForm,
						"eligibleTiers",
						storedAddress?.eligibleTiers,
					),
					isDeliveryActive: getFormValue(
						addressForm,
						"isDeliveryActive",
						storedAddress?.isDeliveryActive,
					),
					isConditionMet: getFormValue(
						addressForm,
						"isConditionMet",
						storedAddress?.isConditionMet,
					),
					priceTiers: getFormValue(
						addressForm,
						"priceTiers",
						storedAddress?.priceTiers,
					),
					city: getFormValue(addressForm, "city", storedAddressCity),
					saveAddress: getFormValue(
						addressForm,
						"saveAddress",
						storedAddress?.saveAddress,
					),
					floor: getFormValue(addressForm, "floor", storedAddress?.floor),
					entrance: getFormValue(
						addressForm,
						"entrance",
						storedAddress?.entrance,
					),
					street_name: getFormValue(
						addressForm,
						"street_name",
						storedAddress?.street_name,
					),
					street_num: getFormValue(
						addressForm,
						"street_num",
						storedAddress?.street_num,
					),
					apartment: getFormValue(
						addressForm,
						"apartment",
						storedAddress?.apartment,
					),
				};
			} else {
				if (!selectedAddressId) {
					return;
				}
				const selectedAddress = deliveryOptions.find(
					address => address.id === selectedAddressId,
				);
				if (selectedAddress) {
					data = {
						...data,
						city_heb_name: selectedAddress.city_heb_name,
						delivery_city_id: selectedAddress.city_id,
						delivery_street_name: selectedAddress.street_name,
						delivery_street_num: selectedAddress.street_num,
						delivery_apartment: selectedAddress.apartment,
						delivery_floor: selectedAddress.floor,
						delivery_entrance: selectedAddress.entrance,
						delivery_cost: selectedAddress?.eligibleTiers?.[0]?.shipping_cost,
						deliveryDays: selectedAddress.deliveryDays,
						eligibleTiers: selectedAddress.eligibleTiers,
						isDeliveryActive: selectedAddress.isDeliveryActive,
						isConditionMet: selectedAddress.isConditionMet,
						priceTiers: selectedAddress.priceTiers,
					};
				}
			}
		}
		return data;
	};

	const getLicenseData = () => {
		const isMedicalCondition = licenseForm.getValues("is_medical_condition");
		const data = isMedicalCondition
			? {
					is_medical_condition: licenseForm.getValues("is_medical_condition"),
			  }
			: {
					is_medical_condition: licenseForm.getValues("is_medical_condition"),
					license_url: licenseForm.getValues("license_url"),
			  };
		if (!!licenseForm.getValues("medical_condition")?.value) {
			data = {
				...data,
				medical_condition: licenseForm.getValues("medical_condition")?.value,
			};
		}
		return data;
	};

	const submitOrder = async formData => {
		let isValid = false;
		await summaryForm.handleSubmit(
			data => {
				isValid = true;
			},
			errors => {
				if (errors.approve) {
					if (deliveryMethod === DELIVERY_METHODS.DELIVERY) {
						toastMessage("יש לאשר משלוח ושינוע", "error");
					} else {
						toastMessage("יש לאשר איסוף עצמי", "error");
					}
				}
			},
		)();

		if (!isValid) {
			return;
		}
		setLoading(true);
		const data = {
			store_id: storeId,
			cart_items: cart.products,
			discount_ids:
				cart?.discounts?.map(discount => discount.discount_id) || [],
			signature_url: signatureMethods?.signatureUrl,
			custom_message: summaryForm.getValues("custom_message"),
			//
			id_url: idForm.getValues("id_url"),
			first_name: idForm.getValues("first_name").trim(),
			last_name: idForm.getValues("last_name").trim(),
			//
			...getLicenseData(),
			...getPrescriptionsData(),
			...getDeliveryData(),
		};
		if (!claims.id_number) {
			data.id_number = idForm.getValues("id_number");
		}
		if (
			selectedAddressId === "other" &&
			userAddressesMethods.addressForm.getValues("saveAddress")
		) {
			await createUserAddress();
		}
		await handleLicenseSave();
		await handlePrescriptionSave();

		clearLocalStorage("idFormValues");
		clearLocalStorage("licenseForm");
		clearLocalStorage("prescriptionForm");
		clearLocalStorage("addressForm");
		clearLocalStorage("summaryForm");

		data = clearTemporaryLinks(data);

		await sendOrder(data);
		setLoading(false);
	};

	const sendOrder = async params => {
		const res = await api.submitUserOrder(params);
		if (res.ok) {
			setOrderInfo({
				submitted: true,
				orderNumber: res.data.order.random_id,
				phone: claims.phone_number,
			});
			window.scrollTo({ top: 0, behavior: "smooth" });
			toastMessage("ההזמנה נשלחה בהצלחה", "success");
			collectAnalytics("new_order", {
				id: res.data.order.random_id,
				storeId: storeId,
			});
			await checkLogin();
			try {
				const affiliation = `${cart.store?.display_name} - ${cart.store?.city?.heb_name}`;
				const items = params.cart_items.map(product => {
					const firstParents = product?.parent_strains_heb_name.map(heb_name =>
						heb_name.replace("'", "").trim(),
					);

					const secondParents = product?.parents_second_strains_heb_name.map(
						heb_name => heb_name.replace("'", "").trim(),
					);

					const parents = [...firstParents, ...secondParents].join(", ");

					const country = COUNTRIES.find(
						country => country.code === product?.origin_country,
					)?.heb_name;

					const productType = listProductTypes.find(type => {
						return type.label === product.product_type;
					})?.longLabel;

					const family = listProductTypes.find(type => {
						return type.label === product.family;
					})?.longLabel;

					return {
						item_name: `${product.heb_name} ${product.eng_name}`,
						item_id: product.id,
						price: product.store_price,
						currency: "ILS",
						manufacturer_series: product?.manufacturer_series_heb_name,
						heb_name: product.heb_name,
						eng_name: product.eng_name,
						product_type: productType,
						item_category: product.category,
						family: family,
						manufacturer: product?.manufacturer_heb_name,
						marketer: product?.marketer_heb_name,
						parents: parents,
						series: product?.series_heb_name,
						item_variant: country,
						website: isWidget ? "Jane widget store" : "Jane",
						quantity: product.count,
						affiliation: affiliation,
					};
				});
				const eventObject = {
					event: "purchase",
					ecommerce: {
						currency: "ILS",
						value: cart.totalPrice,
						shipping: res.data.order.delivery_cost,
						affiliation: affiliation,
						transaction_id: res.data.order.random_id,
						coupon:
							cart?.discounts?.map(discount => discount.title).join(", ") || "",
						items: items,
					},
				};
				collectAnalyticsGoogle(eventObject);
			} catch (error) {
				console.error(error);
			}
			setCart(null);
			removeCart(Number(storeId)); // remove cart from cart list
		} else {
			collectAnalytics("new_order_failed", {
				store_id: storeId,
			});
			toastMessage("שגיאה בהשלמת הזמנה", "error");
		}
	};

	const handleUserAddressChange = async addressId => {
		setSelectedAddressId(addressId);
		let deliveryData = await getUserDeliveryAddressesByCart(
			cart,
			userAddresses,
		);
		deliveryData = deliveryData.find(address => address.id === addressId);
		addressForm.setValue(
			"delivery_cost",
			deliveryData?.eligibleTiers?.[0]?.shipping_cost,
		);
		addressForm.setValue("eligibleTiers", deliveryData.eligibleTiers);
		addressForm.setValue("isDeliveryActive", deliveryData.isDeliveryActive);
		addressForm.setValue("deliveryDays", deliveryData.deliveryDays);
		addressForm.setValue("priceTiers", deliveryData.priceTiers);
		addressForm.setValue("isConditionMet", deliveryData.isConditionMet);
	};

	const handleNewAddressCityChange = async city => {
		const cityId = city.id;
		const deliveryData = await getCartDeliveryInfoByCityId(cityId, cart);
		addressForm.setValue(
			"delivery_cost",
			deliveryData?.eligibleTiers?.[0]?.shipping_cost,
		);
		addressForm.setValue("eligibleTiers", deliveryData.eligibleTiers);
		addressForm.setValue("isDeliveryActive", deliveryData.isDeliveryActive);
		addressForm.setValue("deliveryDays", deliveryData.deliveryDays);
		addressForm.setValue("priceTiers", deliveryData.priceTiers);
		addressForm.setValue("isConditionMet", deliveryData.isConditionMet);
	};

	const canLeaveDocumentsStep = useCallback(() => {
		return new Promise(resolve => {
			switch (step) {
				case USER_INFO:
					idForm.handleSubmit(
						data => {
							completeStep(USER_INFO);
							if (
								disclosureConfig[LICENSE].completed &&
								disclosureConfig[PRESCRIPTION].completed
							) {
								resolve(true);
							} else {
								toastMessage("יש למלא את כל השדות", "error");
								resolve(false);
							}
						},
						errors => {
							uncompleteStep(USER_INFO);
							toastMessage("יש למלא את כל השדות", "error");

							resolve(false);
						},
					)();
					break;
				case LICENSE:
					licenseForm.handleSubmit(
						data => {
							completeStep(LICENSE);
							if (
								disclosureConfig[USER_INFO].completed &&
								disclosureConfig[PRESCRIPTION].completed
							) {
								resolve(true);
							} else {
								toastMessage("יש למלא את כל השדות", "error");
								resolve(false);
							}
						},
						errors => {
							uncompleteStep(LICENSE);
							toastMessage("יש למלא את כל השדות", "error");
							resolve(false);
						},
					)();
					break;
				case PRESCRIPTION:
					prescriptionForm.handleSubmit(
						data => {
							completeStep(PRESCRIPTION);
							if (
								disclosureConfig[USER_INFO].completed &&
								disclosureConfig[LICENSE].completed
							) {
								resolve(true);
							} else {
								toastMessage("יש למלא את כל השדות", "error");
								resolve(false);
							}
						},
						errors => {
							uncompleteStep(PRESCRIPTION);
							toastMessage("יש למלא את כל השדות", "error");
							resolve(false);
						},
					)();
					break;
				default:
					resolve(false);
					break;
			}
		});
	}, [
		step,
		idForm,
		licenseForm,
		prescriptionForm,
		completeStep,
		uncompleteStep,
		disclosureConfig,
	]);

	const canLeaveDeliveryStep = useCallback(() => {
		return new Promise(resolve => {
			switch (deliveryMethod) {
				case PICKUP:
					selfPickUpForm.handleSubmit(
						data => {
							resolve(true);
						},
						errors => {
							resolve(false);
						},
					)();
					break;

				case DELIVERY:
					if (!selectedAddressId) {
						toastMessage("נא לבחור כתובת למשלוח", "error");
						resolve(false);
					} else if (selectedAddressId === "other") {
						addressForm.handleSubmit(
							data => {
								if (data.isDeliveryActive && data.isConditionMet) {
									resolve(true);
								} else {
									toastMessage("בית המרקחת אינו משלח לכתובת שנבחרה", "error");
									resolve(false);
								}
							},
							errors => {
								toastMessage("יש למלא את כל השדות", "error");
								resolve(false);
							},
						)();
					} else {
						const selectedAddress = deliveryOptions.find(
							address => address.id === selectedAddressId,
						);
						if (
							selectedAddress?.isDeliveryActive &&
							selectedAddress?.isConditionMet
						) {
							resolve(true);
						} else {
							toastMessage("הכתובת שנבחרה אינה נתמכת", "error");
							resolve(false);
						}
					}
					break;

				default:
					resolve(false);
					break;
			}
		});
	}, [
		selectedAddressId,
		deliveryOptions,
		deliveryMethod,
		selfPickUpForm,
		addressForm,
	]);

	const constructLicenseAddressOcrData = data => {
		const excludedData = data?.data?.[0]?.user_license ?? [];

		const city = cache.allCities.find(
			city =>
				city?.heb_name?.replace(/\s/g, "") ===
					excludedData?.city?.replace(/\s/g, "") ?? "",
		);
		if (!city) {
			const match = excludedData?.city?.match(/^\p{L}+/u);
			setCityOcrSuggestion(match ? match[0] : null);
		}

		return {
			city: city,
			street_name: excludedData?.street_name,
			street_num: excludedData?.street_num,
			apartment: excludedData?.apartment,
			floor: excludedData?.floor,
			entrance: excludedData?.entrance,
		};
	};

	const onLicenseOcr = data => {
		insertLicenseOcrData(data);

		const addressData = constructLicenseAddressOcrData(data);
		addressForm.setValue("city", addressData.city);
		addressForm.setValue("street_name", addressData.street_name);
		addressForm.setValue("street_num", addressData.street_num);
		addressForm.setValue("apartment", addressData.apartment);
		addressForm.setValue("floor", addressData.floor);
		addressForm.setValue("entrance", addressData.entrance);
	};

	const onPrescriptionsOcr = data => {
		insertPrescriptionOcrData(data);
	};

	return {
		isWidget,
		orderInfo,
		step,
		loading,
		disclosureConfig,
		getRelevantPrescription,
		setStep,
		nextStep,
		validateForm,
		claims,
		signatureMethods, // hook methods
		licenseMethods, // hook methods
		prescriptionMethods, // hook methods
		discount,
		setDiscount,
		canLeaveDocumentsStep,
		canLeaveDeliveryStep,
		onLicenseOcr,
		onPrescriptionsOcr,
		//* DELIVERY
		userAddressesMethods, // hook methods
		storeDeliveryMethods, // hook methods
		selectedAddressId,
		setSelectedAddressId,
		deliveryOptions,
		setDeliveryMethod,
		deliveryMethod,
		getDeliveryInfoByCityId,
		submitDelivery,
		getDeliveryData,
		handleUserAddressChange,
		handleNewAddressCityChange,
		deliveryLoading,
		updateUserAddressesWithDeliveryData,
		//* FROMS
		prescriptionForm, // hook methods
		idForm, // hook methods
		licenseForm, // hook methods
		summaryForm, // hook methods
		selfPickUpForm,
		//* CART
		cart,
		//* STORE
		store,
		//*INPUT MANIPULATIONS:
		updatedFields: {
			...updatedFields,
			...updatedLicenseFields,
			...updatedPrescriptionFields,
		},
		setUpdatedFields,
		//*OCR DATA INSERTIONS:
		insertLicenseOcrData,
		submitOrder,
		handleLicenseSave,
		sortPrescriptionsByDate,
		cityOcrSuggestion,
		setCityOcrSuggestion,
	};
};

export default useCheckout;
