import React from "react";
import { formatPrice } from "util/common";

function CartProductPrice({ product, discounts }) {
	const discount = discounts?.find(discount => {
		return discount?.discounted_products?.some(discountedProduct => {
			return discountedProduct?.product_id === product.id;
		});
	});

	// If there's no discount, simply return the regular price
	if (!discount) {
		return <p>₪{formatPrice(product.store_price * product.count)}</p>;
	}

	const {
		one_time_discount,
		discounted_products,
		cheapest_discounted_product,
		...discountInfo
	} = discount;

	// Case: One-time discount for the cheapest product
	if (
		one_time_discount &&
		cheapest_discounted_product.product_id === product.id
	) {
		const discountedPrice =
			product.store_price * product.count - discount.total_discount;
		return (
			<span className="flex flex-col text-end items-end">
				<p className="text-red-500 font-semibold leading-none">
					₪{formatPrice(discountedPrice)}
				</p>
				<p className="line-through">
					₪{formatPrice(product.store_price * product.count)}
				</p>
			</span>
		);
	}

	// Case: Regular discount applied to multiple products
	const discountedProduct = discounted_products.find(
		discountedProduct => discountedProduct.product_id === product.id,
	);

	if (discountedProduct?.discounted_amount) {
		const originalPrice = discountedProduct.original_price * product.count;
		const priceAfterDiscount =
			originalPrice - discountedProduct.discounted_amount;
		return (
			<span className="flex flex-col text-end items-end">
				<p className="text-red-500 font-semibold leading-none">
					₪{formatPrice(priceAfterDiscount)}
				</p>
				<p className="line-through">₪{formatPrice(originalPrice)}</p>
			</span>
		);
	}

	// Default return if no specific case matches
	return <p>₪{formatPrice(product.store_price * product.count)}</p>;
}

export default CartProductPrice;
